'use client';

import { envs } from '@/layers/core/data/const';

export function EnvTag() {
  if (!envs.env_tag) return null;
  return (
    <div
      className="bg-brand pointer-events-none fixed right-0 top-1/2 z-[9999] rounded-l p-2
        text-xs font-black opacity-90 shadow"
      style={{
        writingMode: 'vertical-rl',
        textOrientation: 'upright',
      }}
    >
      <span
        className="animate-envTag -mb-1 break-keep bg-gradient-to-t from-gray-800 to-orange-600
          bg-[length:100%_100vh] bg-clip-text text-transparent"
      >
        {envs.env_tag}
      </span>
    </div>
  );
}
