'use client';

import LocalFont from 'next/font/local';

const openSans = LocalFont({
  src: [
    {
      path: '../../../public/fonts/OpenSans-VariableFont.woff2',
      style: 'normal',
    },
    {
      path: '../../../public/fonts/OpenSans-VariableFont-Italic.woff2',
      style: 'italic',
    },
  ],
  variable: '--font-open-sans',
  display: 'swap',
});

export function FontConfig() {
  return (
    <style jsx global>{`
      :root {
        --font-open-sans: ${openSans.style.fontFamily};
      }
    `}</style>
  );
}
