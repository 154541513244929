'use client';

import { useState } from 'react';

import { useTranslation } from 'next-i18next';

import { useAppTranslation } from '@/app/i18n/client';
import { useFetch } from '@/layers/core/deal/services/useFetch';

export function HealthCheckAlert() {
  const { t } = useTranslation('common');
  const [isServerOkay, setIsServerOkay] = useState(true);
  const { data, isLoading, error } = useFetch<string>(
    { url: '/healthcheck' },
    {
      refreshInterval: 60000,
      dedupingInterval: 60000,
      onLoadingSlow() {
        setIsServerOkay(false);
      },
      onSuccess() {
        setIsServerOkay(true);
      },
    }
  );

  const serverIsWorkingAsExpected =
    isServerOkay && !error && (data === 'Ok' || isLoading);

  return render(serverIsWorkingAsExpected, t('healthCheckAlert'));
}

export function AppHealthCheckAlert() {
  const { t } = useAppTranslation('common');

  const [isServerOkay, setIsServerOkay] = useState(true);
  const { data, isLoading, error } = useFetch<string>(
    { url: '/healthcheck' },
    {
      refreshInterval: 60000,
      dedupingInterval: 60000,
      onLoadingSlow() {
        setIsServerOkay(false);
      },
      onSuccess() {
        setIsServerOkay(true);
      },
    }
  );

  const serverIsWorkingAsExpected =
    isServerOkay && !error && (data === 'Ok' || isLoading);

  return render(serverIsWorkingAsExpected, t('healthCheckAlert'));
}

function render(serverIsWorkingAsExpected: boolean, msg: string) {
  if (serverIsWorkingAsExpected) return null;
  return (
    <div className="w-full text-balance bg-black p-2 text-center font-medium text-gray-200">
      {msg}
    </div>
  );
}
