'use client';
import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { useTranslation } from 'next-i18next';

import { Button } from '@quick/components/Button';

const cookiesAtom = atomWithStorage('cookieV1', false);

export const CookieNotice = () => {
  const [hasAcceptedCookies, setHasAcceptedCookies] = useAtom(cookiesAtom);
  const { t } = useTranslation('common');

  if (hasAcceptedCookies) return null;
  return (
    <div
      className="bg-brand animate-in motion-safe:slide-in-from-bottom-full fill-mode-both
        fade-in-0 fixed bottom-0 z-40 flex w-full flex-col justify-center gap-4
        rounded-t-lg p-4 text-center font-medium text-black shadow shadow-gray-700
        delay-1000"
    >
      <p>{t('cookiesMsg')}</p>
      <Button
        className="self-center"
        variant="black"
        onClick={() => setHasAcceptedCookies(true)}
      >
        {t('accept')}
      </Button>
    </div>
  );
};
