// @ts-check

/** @type {import('next-i18next').UserConfig} */
const i18nextConfig = {
  debug: process.env.NODE_ENV !== 'production' && !process.env.NO_I18N,
  i18n: {
    locales: ['es', 'en'],
    defaultLocale: 'es',
  },
  // localeStructure: `${process.env.NEXT_PUBLIC_COUNTRY}/{lng}/{ns}`, // In case it is needed
  interpolation: { escapeValue: false },
  returnObjects: true,
  // https://github.com/nrwl/nx/discussions/4983
  localePath: 'public/locales',
};

module.exports = i18nextConfig;
