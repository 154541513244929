import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

/**
 * Merges tailwind styles. It leaves the last style that applies to a property.
 * Taken from https://ui.shadcn.com/docs/installation
 */
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
