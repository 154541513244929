'use client';

import { useCountry } from '@/layers/core/deal/services/CountryContext';

export function ThemeDetector() {
  const { clean } = useCountry();
  const isClean = clean;
  const style = isClean
    ? `
      --brand: 43 171 226;
      --brand-dark: 0 96 223;
      --brand-focus: 64 191 245;
      --brand-disabled: 92 152 225;
    `
    : '';

  return (
    <style jsx global>{`
      :root {
        ${style}
      }
    `}</style>
  );
}
