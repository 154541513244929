'use client';
import { useEffect, useRef } from 'react';

import { useCountry } from '@/layers/core/deal/services/CountryContext';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore Clarity script is not typed
function clarityInit(c, l, a, r, i, t, y) {
  c[a] =
    c[a] ||
    function () {
      // eslint-disable-next-line prefer-rest-params
      (c[a].q = c[a].q || []).push(arguments);
    };
  t = l.createElement(r);
  t.async = 1;
  t.src = 'https://www.clarity.ms/tag/' + i;
  y = l.getElementsByTagName(r)[0];
  y.parentNode.insertBefore(t, y);
}

export function Clarity() {
  const { clarity } = useCountry();
  const mounted = useRef(false);

  useEffect(() => {
    if (!mounted.current && clarity) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore Clarity script is not typed
      clarityInit(window, document, 'clarity', 'script', clarity);
      mounted.current = true;
    }
  }, [clarity]);
  return null;
  // return <script type="text/javascript">
  // (function(c,l,a,r,i,t,y){
  //     c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
  //     t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
  //     y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
  // })(window, document, "clarity", "script", "jvlfm5okwk");
  // </script>
}
