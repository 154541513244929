import type { InitOptions, Namespace } from 'i18next';

import Config from '@/next-i18next.config';

export const fallbackLng = Config.i18n.defaultLocale;
export const languages = Config.i18n.locales;
export const defaultNS = 'common' as const;
export const cookieName = 'i18next';

export function getOptions<const T extends Namespace = 'common'>(
  lng = fallbackLng,
  ns?: T
): InitOptions {
  const ins = ns || defaultNS;
  return {
    // debug: true,
    supportedLngs: languages,
    interpolation: Config.interpolation,
    fallbackLng,
    lng,
    fallbackNS: defaultNS,
    defaultNS,
    ns: ins,
    returnObjects: true,
  };
}
