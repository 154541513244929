import { useMemo } from 'react';

import Bowser from 'bowser';

const conditions = {
  chrome: '>=110',
  firefox: '>=115',
  safari: '>=16',
  edge: '>=110',
  ie: '>11',
  opera: '>=96',
  samsung: '>=21',
};

export default function useCompatibility() {
  return useMemo(() => {
    try {
      const parser = Bowser.getParser(window?.navigator.userAgent);
      const browser = parser.getBrowser();
      const isValidBrowser = parser.satisfies(conditions);

      return { browser, isValidBrowser };
    } catch (_err) {
      return {
        browser: { name: 'Unknown', version: 'Unknown' },
        isValidBrowser: false,
      };
    }
  }, []);
}
