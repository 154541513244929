import { cva, type VariantProps } from 'class-variance-authority';
import { forwardRef } from 'react';

type b = JSX.IntrinsicElements['button'];

export const buttonCStyles = cva(
  'rounded px-4 font-sans text-base font-semibold not-italic transition duration-300 flex items-center justify-center',
  {
    variants: {
      kind: {
        primary: 'py-1.5',
        // py-1.5 - 1px. Just take into account the border
        secondary: 'py-[0.3125rem] border',
        tertiary: '',
      },
      variant: {
        approve: '',
        accept: '',
        reject: '',
        warning: '',
        destruct: '',
        black: '',
      },
    },
    compoundVariants: [
      // Approve
      {
        kind: 'primary',
        variant: 'approve',
        className:
          'bg-brand hover:bg-brand-dark text-background-400 disabled:bg-brand-disabled disabled:text-gray-500',
      },
      {
        kind: 'secondary',
        variant: 'approve',
        className:
          'text-brand bg-gray-700 dark:bg-transparent dark:text-brand-dark hover:text-brand-focus disabled:text-brand-disabled',
      },
      // Accept
      {
        kind: 'primary',
        variant: 'accept',
        className:
          'bg-green-700 hover:bg-green-800 text-white disabled:bg-green-400 disabled:text-gray-600',
      },
      {
        kind: 'secondary',
        variant: 'accept',
        className:
          'text-green-600 hover:text-green-800 disabled:text-green-200',
      },
      // Destruct
      {
        kind: 'primary',
        variant: 'destruct',
        className:
          'bg-red-600 hover:bg-red-700 text-white disabled:bg-red-400 disabled:text-gray-600',
      },
      {
        kind: 'secondary',
        variant: 'destruct',
        className: 'text-red-600 hover:text-red-700 disabled:text-red-400',
      },
      // Warning
      {
        kind: 'primary',
        variant: 'warning',
        className:
          'rounded bg-orange-500 text-white hover:bg-orange-600 disabled:bg-orange-300 dark:disabled:bg-orange-300 dark:disabled:text-gray-600',
      },
      // Reject
      {
        kind: 'primary',
        variant: 'reject',
        className:
          'rounded bg-white border-white hover:bg-black text-black hover:text-white hover:border-black',
      },
      {
        kind: 'secondary',
        variant: 'reject',
        className:
          'bg-background-light hover:bg-gray-light-5 border-gray-300 text-gray-800 disabled:text-gray-300 dark:border-gray-500 dark:hover:bg-gray-500 dark:bg-gray-600 dark:text-gray-300 dark:disabled:bg-gray-500 dark:disabled:text-gray-400',
      },
      // Black
      {
        kind: 'primary',
        variant: 'black',
        className:
          'bg-gray-700 dark:bg-gray-800 text-white hover:text-brand focus:text-brand',
      },
      { kind: 'secondary', variant: 'black', className: 'dark:text-white' },
    ],
    defaultVariants: { variant: 'approve', kind: 'primary' },
  }
);

export interface ButtonProps extends b, VariantProps<typeof buttonCStyles> {
  children: React.ReactNode;
}
const Button = forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  { variant, kind, children, type = 'button', className, ...props },
  forwardRef
) {
  const buttonClassName = buttonCStyles({ variant, kind, className });
  return (
    <button type={type} className={buttonClassName} {...props} ref={forwardRef}>
      {children}
    </button>
  );
});

Button.displayName = 'Button';

export { Button };
