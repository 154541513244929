'use client';

import { useEffect, useState } from 'react';

export function DetectDVH() {
  const [vh, setVH] = useState<string>('1vh');
  useEffect(() => {
    const handler = () => setVH(`${window.innerHeight * 0.01}px`);
    handler();
    window.addEventListener('resize', handler);
    return () => window.removeEventListener('resize', handler);
  }, []);
  return (
    <style jsx global>{`
      :root {
        --vh: ${vh};
      }
    `}</style>
  );
}
